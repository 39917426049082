import React, {createContext, FC, useState} from "react";
import {Event} from "../model/Event";

export type SettingsContextType = {
    event: Event;
    setEvent: (event: Event) => void
}

const InitialSettings = {
    identifier: '',
    firstNameRequired: false,
    lastNameRequired: false,
    phoneRequired: false,
    emailRequired: false,
    zipCodeRequired: false,
    loyaltyCardNumberRequired: false,
    eventType: '',
    maxPrizePerDay: 0,
    maxPrizeTotal: 0,
    maxChances: 0,
    maxReceipts: 0,
    chanceStep: 0,
    animationFrom: 0,
    animationTo: 0,
    animationColor: '#000000',
    agreements: ['']
}

const InitialEvent = {
    name: '',
    slug: '',
    gallery: '',
    lotteryPanel: '',
    winAnimation: '',
    loseAnimation: '',
    settings: InitialSettings
}

const InitialSettingsContext: SettingsContextType = {
    event: InitialEvent,
    setEvent: (event: Event) => {
    }
}

const SettingsContext = createContext(InitialSettingsContext);

type Props = {
    children: any,
}
export const SettingsContextProvider: FC<Props> = ({children}: Props) => {
    const [event, setEvent] = useState<Event>(InitialEvent);

    const contextValue: SettingsContextType = {
        event,
        setEvent
    }

    return <SettingsContext.Provider value={contextValue}>{children}</SettingsContext.Provider>
}

export default SettingsContext;
