import React, {FC, useContext, useState} from "react";
import {LotteryApiResponse} from "../model/LotteryApiResponse";
import LotteryPanel from "../components/LotteryPanel";
import LotteryAnimation from "../components/LotteryAnimation";
import AlertContext from "../context/alert-context";
import CustomSnackbar from "../components/CustomSnackbar";

const LotteryPage: FC = () => {
    const [prize, setPrize] = useState<string | undefined | null>(null);
    const alertCtx = useContext(AlertContext);
    const [showAnimation, setShowAnimation] = useState(false);

    const handleAnimation = (response: LotteryApiResponse) => {
        setPrize(response.prize);
        setShowAnimation(true);
    }

    const closeAnimation = () => {
        setPrize(null);
        setShowAnimation(false);
    }

    return <>
        {!showAnimation && <LotteryPanel handleAnimation={handleAnimation}/>}
        {showAnimation && <LotteryAnimation prizeName={prize} handleAnimation={closeAnimation}/>}
        <CustomSnackbar open={alertCtx.open}  title={alertCtx.title}  xl={alertCtx.xl} severity={alertCtx.severity}/>
    </>
}

export default LotteryPage;
