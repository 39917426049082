
export const EMAIL_ID_FIELD = 'email'
export const PHONE_ID_FIELD = 'phone'
export const LOYALTY_CARD_NUMBER_ID_FIELD = 'loyalty_card_number'
class Settings {
    identifier: string;
    firstNameRequired: boolean;
    lastNameRequired: boolean;
    phoneRequired: boolean;
    emailRequired: boolean;
    zipCodeRequired: boolean;
    loyaltyCardNumberRequired: boolean;
    eventType: string;
    maxPrizePerDay: number;
    maxPrizeTotal: number;
    maxChances: number;
    maxReceipts: number;
    chanceStep: number;

    animationFrom: number;
    animationTo: number;
    animationColor: string;
    agreements: string[];

    constructor(identifier: string, firstNameRequired: boolean, lastNameRequired: boolean, phoneRequired: boolean, emailRequired: boolean, zipCodeRequired: boolean, loyaltyCardNumberRequired: boolean, eventType: string, maxPrizePerDay: number, maxPrizeTotal: number, maxChances: number, maxReceipts: number, chanceStep: number, animationFrom: number, animationTo: number, animationColor: string, agreements: string[]) {
        this.identifier = identifier;
        this.firstNameRequired = firstNameRequired;
        this.lastNameRequired = lastNameRequired;
        this.phoneRequired = phoneRequired;
        this.emailRequired = emailRequired;
        this.zipCodeRequired = zipCodeRequired;
        this.loyaltyCardNumberRequired = loyaltyCardNumberRequired;
        this.eventType = eventType;
        this.maxPrizePerDay = maxPrizePerDay;
        this.maxPrizeTotal = maxPrizeTotal;
        this.maxChances = maxChances;
        this.maxReceipts = maxReceipts;
        this.chanceStep = chanceStep;
        this.animationFrom = animationFrom;
        this.animationTo = animationTo;
        this.animationColor = animationColor;
        this.agreements = agreements;
    }

    public static fromApiResponse(data: any){
        return new Settings(
            data?.identifier ? data.identifier : '',
            data?.first_name_required,
            data?.last_name_required,
            data?.phone_required,
            data?.email_required,
            data?.zip_code_required,
            data?.loyalty_card_number_required,
            data?.event_type ? data.event_type : '',
            data?.max_prize_per_day ? data?.max_prize_per_day : 0,
            data?.max_prize_total ? data?.max_prize_total : 0,
            data?.max_chances ? data?.max_chances : 0,
            data?.max_receipts ? data?.max_receipts : 0,
            data?.chance_step ? data?.chance_step : 0,
            data?.animation_from ? data.animation_from : 0,
            data?.animation_to ? data.animation_to : 0,
            data?.animation_color ? data.animation_color : '#000000',
            data?.agreements ? data.agreements.map((a: any) => a.content) : []
        )
    }
}

export default Settings;