import axios from 'axios';
import {Event} from '../model/Event';

export const getEventSettings = (token: string | undefined, handleData: (event: Event) => void, onError?: (error: string) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/api/events' : '';
    const config = {
        headers: {
            'Accept-Content': 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    }
    axios.get(url, config).then((response) =>{
        if (response.status == 200) {
            const event = Event.fromApiResponse(response.data[0])
            handleData(event);
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response.data.message? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : 'Błąd serwera!');
    })
}