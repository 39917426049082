import React, { createContext, FC, useState} from "react";

export type AlertContextType = {
    severity: "error" | "info" | "success" | "warning";
    title: string;
    open: boolean;
    close: () => void;
    xl: boolean;
    setAlert: (alert : {title: string, severity?: "error" | "info" | "success" | "warning", xl?: boolean}) => void;
}

const InitialAlertContext: AlertContextType = {
    severity: "info",
    title: "",
    open: false,
    close: () => {},
    xl: false,
    setAlert: ({title: string, severity, xl}) => {},
}

const AlertContext = createContext(InitialAlertContext);

type Props = {
    children: any
}
export const AlertContextProvider: FC<Props> = ({children}: Props) => {
    const [title, setTitle] = useState("");
    const [xl, setXl] = useState(false);
    const [severity, setSeverity] = useState<"error" | "info" | "success" | "warning">("error");

    const setAlert = ({title, severity, xl}: {title: string, severity?: "error" | "info" | "success" | "warning", xl?: boolean}) => {
        setTitle(title);
        if(severity) {
            setSeverity(severity);
        } else {
            setSeverity("error");
        }
        if(xl) {
            setXl(xl);
        }
        const timeout = setTimeout(()=> {
            setTitle("");
        }, 5000);

        return (() => {clearTimeout(timeout)})
    }

    const contextValue: AlertContextType = {
        ...InitialAlertContext,
        severity: severity,
        title: title,
        xl: xl,
        setAlert: setAlert,
        close: () => {setTitle("")},
        open: title !="",
    }

    return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>
}

export default AlertContext;
