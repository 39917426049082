import React, {FC, useContext, useState} from "react";
import {Box, Collapse, Grid, Grow} from "@mui/material";
import SettingsContext, {SettingsContextType} from "../context/settings-context";
import { default as _ReactPlayer } from 'react-player/lazy';
import { ReactPlayerProps } from "react-player/types/lib";
import Settings from "../model/Settings";

type Props = {
    prizeName: string | undefined | null;
    handleAnimation: () => void;
}

const LotteryAnimation: FC<Props> = ({prizeName, handleAnimation}) => {
    const {event:{slug, winAnimation, loseAnimation, settings}} = useContext(SettingsContext);
    const isPrize = prizeName !== 'undefined' && prizeName !== '' && prizeName != null;
    const [winLottery, setWinLottery] = useState(isPrize)
    const [showPrize, setShowPrize] = useState(false)

    const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

    const winMovieHandler = () => {
        setWinLottery(false)
        setShowPrize(false)
        handleAnimation()
    }

    const loseMovieHandler = () => {
        setWinLottery(false)
        setShowPrize(false)
        handleAnimation()
    }

    const handleWinProgress = (time: any) => {
        const animationStart = Number(settings.animationFrom)
        const animationEnd = Number(settings.animationTo)
        if (time.playedSeconds > animationStart && !showPrize && time.playedSeconds < animationEnd) {
            setShowPrize(true)
        }
        if(showPrize && time.playedSeconds >  animationEnd) {
            setShowPrize(false)
        }
    }

    return (
        <>
            <Grid container sx={{position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden'}}>
                <Grid item sx={{
                    position: 'absolute',
                    top: '55%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 4,
                    fontFamily: 'Arial',
                    color: settings.animationColor,
                    fontSize: '100px',
                    lineHeight: 1,
                    fontWeight: '600',
                    overflow: 'hidden',
                    width: "55vw",
                }}>
                    <Collapse in={showPrize} orientation="horizontal">
                        <Box sx={{maxHeight: "30%", width: "55vw", textAlign: "center"}}>
                            {isPrize ? prizeName: ''}
                        </Box>
                    </Collapse>
                </Grid>
                <Grid item sx={{
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100vw',
                    height: '100vh'
                }}>
                    <Box sx={{
                        zIndex: 3,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh'
                    }}>
                        {winLottery &&
                            <ReactPlayer
                                url={winAnimation}
                                playing={true}
                                muted={false}
                                width={'100%'}
                                height={'auto'}
                                progressInterval={500}
                                onEnded={winMovieHandler}
                                onProgress={handleWinProgress}
                            />
                        }
                    </Box>
                    <Box sx={{
                        zIndex: 3,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh'
                    }}>
                        {!winLottery &&
                            <>
                            <ReactPlayer
                                url={loseAnimation}
                                playing={true}
                                muted={false}
                                width={'100%'}
                                height={'auto'}
                                onEnded={loseMovieHandler}
                            />
                        </>}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default LotteryAnimation;

