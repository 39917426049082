import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {Box, Button, CircularProgress, ClickAwayListener, Grid, styled, TextField,} from "@mui/material";
import AlertContext from "../context/alert-context";
import {winPrize} from "../api/prize-api";
import AuthContext from "../context/auth-context";
import Icon from "@mui/material/Icon";
import 'react-simple-keyboard/build/css/index.css';
import './LotteryPanel.css';
import {LotteryApiResponse} from "../model/LotteryApiResponse";
import SettingsContext from "../context/settings-context";
import {SCANNED_COUPONS_EVENT_TYPE, SMS_EVENT_TYPE} from "../model/Event";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const SquareButton = styled(Button)({
    width: '70%',
    height: '70px',
    marginTop: "20px !important",
    color: '#052954',
    background: 'white',
    borderRadius: 0,
    fontSize: '24px',
    fontWeight: '600',
    "&:hover": {
        backgroundColor: '#fff',
        color: '#013D7CCC !important',
    },
    "&:focus": {
        backgroundColor: '#fff',
        color: '#013D7CCC !important',
    },
    "&:active": {
        backgroundColor: '#fff',
        color: '#013D7CCC !important',
    }
})

type LotteryPanelProps = {
    handleAnimation: (response: LotteryApiResponse) => void;
}

const LotteryPanel: FC<LotteryPanelProps> = ({handleAnimation}) => {
    const {event: {lotteryPanel, slug, settings}} = useContext(SettingsContext);
    const {user} = useContext(AuthContext);
    const couponCodeRef = useRef();
    const alertCtx = useContext(AlertContext);
    const {logout} = useContext(AuthContext);
    const [isSending, setIsSending] = useState(false);
    const [couponNumber, setCouponNumber] = useState({coupon: ''})
    const [couponValidation, setCouponValidation] = useState({couponError: '',})
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

    const focusInput = () => {
        if (couponCodeRef.current) {
            // @ts-ignore
            couponCodeRef.current.focus();
        }
    }

    useEffect(() => {
        focusInput();
    })

    useEffect(() => {
        if (couponNumber.coupon && couponNumber.coupon.length === 8) {
            onClick();
        }
    }, [couponNumber])

    const couponNumberHandler = (input: string) => {
        setCouponNumber((prevState) => {
            const code = input.trim().replace(/[^0-9]/g, '')
            console.log(code);
            const rex = new RegExp('^[0-9]{0,8}$')
            const value = rex.test(code) ? code : prevState.coupon
            if (value.length === 8) {
                setCouponValidation({
                    ...couponValidation,
                    couponError: ''
                })
            } else {
                // setCouponValidation({
                //     ...couponValidation,
                //     couponError: 'Numer kuponu powinien mieć 8 liczb!'
                // })
            }
            return {
                coupon: value
            }
        })
    }

    const setIsSendingWithMessage = () => {
        setIsSending(true);
        return setTimeout(() => {
            setIsSending(false);
            alertCtx.setAlert({title: "Problem z połączeniem...", severity: 'info', xl: true})
        }, 10000);
    }

    const onClick = () => {

        if (couponNumber.coupon && couponValidation.couponError == '') {
            const timeout = setIsSendingWithMessage();
            winPrize(user?.token, couponNumber.coupon, slug, (lotteryApiResponse: LotteryApiResponse) => {
                handleAnimation(lotteryApiResponse);
                setIsSending(false);
                clearTimeout(timeout);
            }, (error: any) => {
                alertCtx.setAlert({title: error, severity: 'info', xl: true})
                setIsSending(false);
                clearTimeout(timeout);
            })
            setCouponNumber({coupon: ''})
        } else {
            alertCtx.setAlert({title: 'Wprowadź numer kuponu', severity: 'info', xl: true})
        }
        setIsKeyboardVisible(false);
    }

    return (
        <Grid container direction="row"
              justifyContent={"space-between"}
              component='main'
              width={'100vw'}
              height={'100vh'}
              sx={{
                  zIndex: 1,
                  overflow: "hidden",
                  position: "relative",
                  background: `url(${lotteryPanel})`,
                  backgroundSize: "100% 100%",
                  backgroundPosition: "center"
              }}
        >
            <Grid item container justifyContent={"center"} sx={{
                minWidth: "50%",
                maxWidth: "50%",
                position: "relative",
                objectFit: "cover",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}
            />
            <Grid item display='flex' flexDirection="column" justifyContent='space-between' alignItems='center'
                  height={'100vh'} xs={5} pt={8} pb={8} sx={{
                zIndex: 2,
                position: "relative",
                minWidth: "50%",
                maxWidth: "50%",
            }}>
                <Box sx={{
                    position: "absolute",
                    top: 0,
                    mt: "40px",
                    mb: "40px",
                    right: "40px",
                    height: "calc(100% - 80px)",
                    width: "100%",
                }}/>
                <Button disableRipple startIcon={<Icon sx={{color: "transparent!important"}}>lock_open</Icon>}
                        onClick={logout}
                        sx={{position: "absolute", top: 0, right: 0, color: "transparent!important"}}>
                    Wyloguj
                </Button>
                <Grid item container flexDirection={"column"} justifyContent={"end"} alignItems={"center"}
                      sx={{zIndex: 3, width: "80%", position: "relative", height: "100%", mb: "340px"}}>
                    <ClickAwayListener onClickAway={() => focusInput()}>
                        <>
                            <TextField
                                value={couponNumber.coupon}
                                onChange={({target: {value}}) => setCouponNumber({coupon: value})}
                                onClick={() => setIsKeyboardVisible(true)}
                                placeholder={"_ _ _ _ _ _ _ _"}
                                variant={"standard"}
                                inputRef={couponCodeRef}
                                // type={"password"}
                                helperText={couponValidation.couponError}
                                error={couponValidation.couponError !== ''}
                                required
                                className={"fieldset"}
                                FormHelperTextProps={{
                                    sx: {fontSize: "24px !important"},
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    sx: {
                                        maxHeight: '60px !important',
                                        fontSize: '60px !important',
                                        lineHeight: "50%",
                                        textAlign: 'center',
                                        boxShadow: 'none',
                                        letterSpacing: '10px',
                                        color: "black !important",
                                        zIndex: 3,
                                    },
                                }}
                                inputProps={{
                                    sx: {
                                        textAlign: 'center',
                                    }
                                }}
                                sx={{
                                    width: '70%',
                                    border: "none",
                                    "& fieldset": {
                                        borderRadius: "0 !important",
                                    },
                                }}
                            />
                            {settings.eventType == SMS_EVENT_TYPE && isKeyboardVisible &&
                                <Box sx={{
                                    position: "absolute",
                                    top: "80%",
                                    left: "-50%",
                                    zIndex: 100,
                                    minWidth: "450px",
                                    minHeight: "450px",
                                }}
                                > <Keyboard
                                    maxLength={8}
                                    display={{
                                        '{bksp}': '&larr;',
                                        '{exit}': 'zamknij',
                                    }}
                                    onInit={(keyboard: any) => setCouponNumber({coupon: ''})}
                                    buttonTheme={[{
                                        class: "keyboard-button",
                                        buttons: "1 2 3 4 5 6 7 8 9 0 {bksp} {exit}"
                                    }]}
                                    layout={{
                                        'numPad': [
                                            '1 2 3',
                                            '4 5 6',
                                            '7 8 9',
                                            '{exit} 0 {bksp}',
                                        ]
                                    }}
                                    layoutName={'numPad'}
                                    onKeyPress={(button: any) => {
                                        if (button == "{exit}") {
                                            if (couponNumber.coupon == '') {
                                                setCouponValidation({
                                                    ...couponValidation,
                                                    couponError: ''
                                                })
                                            }
                                            setIsKeyboardVisible(false);
                                        }
                                    }}
                                    onChange={couponNumberHandler}
                                />
                                </Box>
                            }
                            {/*{SMS_EVENT_TYPE && <SquareButton onClick={onClick}>*/}
                            {/*    {isSending ? <CircularProgress color={"success"}/> : "Sprawdź, czy wygrałeś!"}*/}
                            {/*</SquareButton>}*/}
                        </>
                    </ClickAwayListener>
                </Grid>
                <Grid item container justifyContent={"center"} sx={{zIndex: 3, width: "80%"}}>
                    {/*<img src={"/images/av-logo.png"} alt={"Avenida logo"} width={"35%"}/>*/}
                </Grid>
            </Grid>
        </Grid>

    )
}

export default LotteryPanel;
