import Settings from "./Settings";

export const INSTANT_WIN_EVENT_TYPE = 'INSTANT_WIN_EVENT_TYPE'
export const SCANNED_COUPONS_EVENT_TYPE = 'SCANNED_COUPONS_EVENT_TYPE'
export const SMS_EVENT_TYPE = 'SMS_EVENT_TYPE'
export class Event {
    name: string;
    slug: string;
    gallery: string;
    lotteryPanel: string;
    winAnimation: string;
    loseAnimation: string;
    settings: Settings;

    constructor(name: string, slug: string, gallery: string, lotteryPanel: string, winAnimation: string, loseAnimation: string, settings: Settings) {
        this.name = name;
        this.slug = slug;
        this.gallery = gallery;
        this.lotteryPanel = lotteryPanel;
        this.winAnimation = winAnimation;
        this.loseAnimation = loseAnimation;
        this.settings = settings;
    }

    public static fromApiResponse(data: any) {
        return new Event(
            data?.name ? data.name : '',
            data?.slug ? data.slug : '',
            data?.gallery_name ? data.gallery_name : '',
            data?.lottery_panel ? data.lottery_panel : '',
            data?.win_animation ? data.win_animation : '',
            data?.lose_animation ? data.lose_animation : '',
            Settings.fromApiResponse(data?.settings),
        );
    }
}