import axios from "./api-client";
import {LotteryApiResponse} from "../model/LotteryApiResponse";

export const winPrize = (token: string | undefined, code: string, eventSlug: string, handleData?: (lotteryApiResponse: LotteryApiResponse) => void, onError?: (error: string) => void) => {
    const config = {
        headers: {
            "Accept-Content": "application/json",
            'Authorization' : 'Bearer ' + token
        }
    }
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + `/api/events/${eventSlug}/prizes` : '';
    axios.post(url, {code: code}, config).then((response) =>{
        if (response.status == 200) {
            handleData && handleData(response.data as LotteryApiResponse);
        } else {
            onError && onError(JSON.stringify(response.data.message));
        }
    }).catch((error) => {
        console.error(error.response.data.message ? error.response.data.message : error);
        onError && onError(error.response.data.message ? error.response.data.message : "Błąd serwera!");
    })
}
