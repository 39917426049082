import React, {useContext, useEffect} from 'react';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {Navigate, Route, Routes} from "react-router-dom";
import AuthContext from "./context/auth-context";
import theme from "./style/theme";
import Login from "./pages/Login";
import LotteryPage from "./pages/LotteryPage";
import {getEventSettings} from "./api/content-api";
import SettingsContext from "./context/settings-context";
import AlertContext from "./context/alert-context";

const App = () => {
    const {isLoggedIn, user} = useContext(AuthContext);
    const {setEvent} = useContext(SettingsContext);
    const {setAlert} = useContext(AlertContext);

    useEffect(() => {
        if(user?.token) {
            getEventSettings(user?.token, (event) => {
                document.title = 'Loteriomat - ' + event.name
                setEvent(event);
            }, (error) => {
                setAlert({title: error})
            })
        } else {
            document.title = 'Loteriomat'
        }
    }, [user])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Routes>
                <Route path={"/login"} element={<Login/>} key={"login"}/>
                {user?.access && <Route path={"/" + user?.access} element={<LotteryPage/>} key={"lottery"}/>}
                {user?.access && <Route path={"*"} element={<Navigate to={"/" + user?.access}/>} key={"redirect"}/>}
                {!user?.access && <Route path={"*"} element={<Navigate to={"/login"}/>} key={"redirect"}/>}
            </Routes>
        </ThemeProvider>
    );
}

export default App;
