import {createTheme} from "@mui/material";

const theme = createTheme({
    palette:{
        warning: {
            main: "#FFA726",
            dark: "#FB8C00",
        },
        success: {
            main: "#66BB6A",
            dark: "#43A047",
        },
    }
});

export default theme;