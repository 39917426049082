import {Alert, Fade, Snackbar} from "@mui/material";
import {FC, useState} from "react";

type Props = {
    severity: "error" | "info" | "success" | "warning";
    title: string;
    xl: boolean;
    open: boolean;
}
const CustomSnackbar: FC<Props> = ({severity, title, open, xl}: Props) => {
    return <Snackbar open={open}
                     TransitionComponent={Fade}
                     autoHideDuration={5000}
                     anchorOrigin={{
                         vertical: "bottom",
                         horizontal: xl ? "center" : "right",
                     }}>
        <Alert severity={severity} sx={xl ? { width: '90vw', fontSize: "96px", mr: 2} : { width: '100%'}}>
            {title}
        </Alert>
    </Snackbar>
}

export default CustomSnackbar;